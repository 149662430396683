.header{
    background-color: #000000;
    text-align: center;
    padding: 10px;
}
.header > h4{
    font-size: 40px;
    color: #ffffff;
}

.faqSection{
    padding: 0 30px 0 30px;
}
.chakra-accordion__item > button{
    font-size: 25px;
}